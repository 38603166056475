/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-07 15:48:15 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-Th 09:37:43
 */
<template>
  <div class="practice">
    <el-row
      type="flex"
      justify="center"
      v-for="(li, i) in list"
      :key="i"
      @click.native="parcticeClickPattern(li)"
    >
      <el-col :span="16" style="padding-left: 10px">
        {{ li.CategroyName }}
        （
        <span class="c_999999">
          {{ li.DoCount }}
          <!-- /{{ li.AllCount }} -->
        </span>
        ）
      </el-col>
      <el-col :span="8" class="text_align_right">
        <!-- <p class="c_1288F4">开始练习</p> -->
        <img src="@/assets/images/icon_per.png" width="18" alt="" />
      </el-col>
    </el-row>
    <!-- <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="page"
      :page-size="pageSize"
      layout="prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination> -->
  </div>
</template>

<script>
import { querySentenceCategory } from "@/api/questionBank";
import noData from "@/components/noData";
export default {
  data () {
    return {
      page: 1,
      pageSize: 10,
      total: 0,
      list: [],
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
    };
  },
  components: {
    noData
  },
  created () { },
  mounted () {
    this.route = this.$route.query
    this.init();
  },
  watch: {
    $route: "init",
  },
  methods: {
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.init();
    },
    // 句式分析跳转
    parcticeClickPattern (obj) {
      let path = "/onlineQuestionBank/translatePractice"
      let ob = {
        UserID: this.userInfo.Id,
        courseId: this.route.CourseId,
        CategroryId: obj.CategroryId,
        type: 0,
        exercises: 1
      }
      this.$router.push({ path: path, query: ob });
    },
    async init () {
      let parm =
        "?courseId=" +
        this.route.CourseId +
        "&studentId=" +
        this.userInfo.Id
      const res = await querySentenceCategory(parm);
      if (res.success == true) {
        this.list = res.response
      }
    },
  },
};
</script>

<style lang="less" scoped>
.practice {
  .el-row {
    .el-col {
      line-height: 50px;
      border-bottom: 1px solid #f0eeee;
    }
  }
}
</style>